import {ItemModal} from "../../common/ItemModal";
import {GlobalContext} from "../../../GlobalContext";
import {Col, Form, FormControl, Table} from "react-bootstrap";
import React from "react";
import {ClusterDetail} from "./ClusterDetail";

export class AdminClusterModelModal extends ItemModal {
    static contextType = GlobalContext;
    static CATEGORIES = [
        {name: 'None', value: ''},
        {name: 'Patient', value: 'PATIENT'},
        {name: 'ICD10', value: 'ICD10'}
    ];

    constructor(props) {
        super(props);
        this.state = {
            orderMetadata: []
        }
    }

    becameVisible = () => {
        if (this.props.item.id) {
            this.context.apiRequest('GET', `/bi/kmeans/model/${this.props.item.id}`)
                .then(d => this.setState({item: d.data}));
        }

        this.context.cachedGet("/superbill/orderMetadata", 300)
            .then(d => {
                if (d && d.data) {
                    this.setState({orderMetadata: d.data});
                }
            })
    };

    onClusterChange = (cluster, e) => {
        let fieldName = e.target.name;
        let val = e.target.value;
        cluster[fieldName] = val;
        let newClusters = [...this.state.item.clusters];
        this.setItemField("clusters", newClusters);
        console.log(`Cluster change: ${fieldName} = ${JSON.stringify(val)}`);
    }

    activeToggled = (e) => {
        this.setItemField("active", e.target.checked ? 1 : 0);
    }

    renderFormContents = (model) => {
        return <div>
            <Form.Row>
                <Col><h4>Model Details</h4></Col>
            </Form.Row>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group controlId="name">
                        <Form.Label size="sm">Model Name:</Form.Label>
                        <FormControl required name={"name"} value={model.name} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={4}>
                    <Form.Group controlId="category">
                        <Form.Label size="sm">Category:</Form.Label>
                        <FormControl name={"category"} as={"select"} custom={true} value={model.category} onChange={this.itemFieldChanged}>
                            {
                                AdminClusterModelModal.CATEGORIES.map(c => <option key={c.value} value={c.value}>{c.name}</option>)
                            }
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="active">
                        <Form.Label size="sm">Active:</Form.Label>
                        <Form.Check
                            type="switch"
                            id="active"
                            name={"active"}
                            checked={model.active == 1}
                            onChange={this.activeToggled}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs={6}>
                    <Form.Group controlId="description">
                        <Form.Label size="sm">Description:</Form.Label>
                        <FormControl as={"textarea"} rows={3} name={"description"} value={model.description || ""} onChange={this.itemFieldChanged}/>
                    </Form.Group>
                </Col>
            </Form.Row>
            {
                !model.id && <Form.Row>
                    <Col xs={6}>
                        <Form.Group controlId="content">
                            <Form.Label size="sm">Raw model content:</Form.Label>
                            <FormControl as={"textarea"} rows={10} name={"content"} value={model.content || ""} onChange={this.itemFieldChanged}/>
                        </Form.Group>
                    </Col>
                </Form.Row>
            }
            {
                model.id && <>
                    <Form.Row>
                        <Col><h4>Clusters</h4></Col>
                    </Form.Row>
                    <Table striped borderless>
                        <tbody>
                        {
                            model.clusters && model.content && model.clusters.map(c => <tr key={c.uuid}>
                                <td>
                                    <ClusterDetail orderMetadata={this.state.orderMetadata} cluster={c} modelContent={model.content} onClusterChange={(e) => this.onClusterChange(c, e)}/>
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </>
            }
        </div>
    }
}