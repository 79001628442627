import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Col, Container, FormCheck, ListGroup, Row} from "react-bootstrap";
import {PatientListRow} from "./PatientListRow";
import {PatientSearch} from "../common/patient/PatientSearch";

export class SuperbillHome extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            patients: [],
            myPatients: false, // TODO: COOKIE
            patientId: null
        }
    }

    componentDidMount() {
        this.setState({myPatients: this.context.userProfile.providerId ? true : false}, this.loadPatients);
    }

    loadPatients = () => {
        let submittedOnly = true;
        if (this.context.hasAnyRole(["System Admin", "Superbill Editor"])) submittedOnly = false;

        this.context.apiRequest("GET", `/superbill/todaysPatients?submittedOnly=${submittedOnly}${this.state.myPatients ? `&providerId=${this.context.userProfile.providerId || ''}` : ''}${this.state.patientId ? `&patientId=${this.state.patientId}` : ''}`)
            .then(d => {
                if (d && d.data) {
                    this.setState({patients: d.data});
                }
            });
    }

    toggleMyPatients = () => {
        this.setState({myPatients: !this.state.myPatients}, this.loadPatients);
    }

    patientSelected = (patientId) => {
        this.setState({patientId: patientId}, this.loadPatients);
    }

    render() {
        return <Container fluid>
            <Row>
                <Col xl={6}>
                    <Row className={""} style={{height: "3.5rem"}}>
                        <Col sm={"auto"} className={"align-self-center"}>
                            <h3>Today's Patients:</h3>
                        </Col>
                        { // only show checkbox if current user is a provider
                            this.context.userProfile.providerId &&
                            <Col className={"text-right align-self-center mb-2"}>
                                <FormCheck inline type={"checkbox"} label={"My Patients Only"} onChange={this.toggleMyPatients} checked={this.state.myPatients}/>
                            </Col>
                        }
                        <Col className={"text-right align-self-center"}>
                            <PatientSearch id={"superbillPtSearch"} clearOnSelect={false} showNewButton={false} showGoButton={false} patientSelected={this.patientSelected} onInputChanged={() => this.patientSelected(null)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ListGroup variant={"flush"} className={"mb-4"}>
                                {
                                    this.state.patients.map(p => <PatientListRow key={p.patientId+p.appointmentDate} patient={p}/>)
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}