import React from "react";
import {Button, Modal} from "react-bootstrap";

export class ConfirmModal extends React.Component {

    render() {
        return <Modal size={"sm"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title size={"sm"}>{this.props.title || 'Confirm'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.prompt || 'Are you sure?'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    {this.props.cancelLabel || "Cancel"}
                </Button>
                <Button variant="dark" onClick={this.props.onOk}>
                    {this.props.okLabel || "OK"}
                </Button>
            </Modal.Footer>
        </Modal>
    }
}