import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Tab, Col, Nav, Row, Tabs, Container} from "react-bootstrap";
import {AdminUserList} from "./AdminUserList";
import {AdminSystemConfigTable} from "./AdminSystemConfigTable";
import {AdminSocRuleList} from "./AdminSocRuleList";
import {AdminSocTestForm} from "./AdminSocTestForm";
import {AdminSocExceptionForm} from "./AdminSocExceptionForm";
import {AdminTestReportsForm} from "./AdminTestReportsForm";
import {AdminLookupTablesList} from "./AdminLookupTablesList";
import {RoleWrapper} from "../common/RoleWrapper";
import {AdminFileUploadForm} from "./AdminFileUploadForm";
import {AdminClusterModelList} from "./clusterModels/AdminClusterModelList";

export class AdminHome extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Container fluid={true}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={this.context.hasAnyRole("System Admin") ? "users" : "lookupTables"}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <RoleWrapper roles={["System Admin"]}>
                                <Nav.Item>
                                    <Nav.Link eventKey="users" className={"btn-light mb-2"}>Manage Users</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="config" className={"btn-light mb-2"}>System Config</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="soc" className={"btn-light mb-2"}>Standard of Care Rules</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="testreports" className={"btn-light mb-2"}>Test Automated Reports</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fileUpload" className={"btn-light mb-2"}>Manual CSV Upload</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="clusterModels" className={"btn-light mb-2"}>Cluster Models</Nav.Link>
                                </Nav.Item>
                            </RoleWrapper>
                            <Nav.Item>
                                <Nav.Link eventKey="lookupTables" className={"btn-light mb-2"}>Lookup Tables</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={8}>
                        <Tab.Content>
                            <RoleWrapper roles={["System Admin"]}>
                                <Tab.Pane eventKey="users">
                                    <AdminUserList/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="config">
                                    <AdminSystemConfigTable/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="soc">
                                    <Tabs id="socTabs" defaultActiveKey={"soc_rules"} className={"mt-3"}>
                                        <Tab eventKey="soc_rules" title="Rules" className={"formTab"}>
                                            <AdminSocRuleList modalSize={"lg"}/>
                                        </Tab>
                                        <Tab eventKey="soc_exceptions" title="Exceptions" className={"formTab"}>
                                            <AdminSocExceptionForm/>
                                        </Tab>
                                        <Tab eventKey="soc_test" title="Test" className={"formTab"}>
                                            <AdminSocTestForm/>
                                        </Tab>
                                    </Tabs>
                                </Tab.Pane>
                                <Tab.Pane eventKey="testreports">
                                    <AdminTestReportsForm/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fileUpload">
                                    <AdminFileUploadForm/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="clusterModels">
                                    <AdminClusterModelList modalSize={"xl"}/>
                                </Tab.Pane>
                            </RoleWrapper>
                            <RoleWrapper roles={["System Admin", "Supervisor"]}>
                                <Tab.Pane eventKey="lookupTables">
                                    <AdminLookupTablesList/>
                                </Tab.Pane>
                            </RoleWrapper>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            </Container>
        );
    }
}