import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {
    Button,
    Card,
    Col,
    Container,
    Form, FormControl,
    FormGroup,
    FormLabel,
    ListGroup,
    ListGroupItem,
    Row,
    Table
} from "react-bootstrap";
import moment from "moment-timezone";
import {Glyph} from "../common/Glyph";
import {ICD10Search} from "../common/ICD10Search";
import {SuperbillOrderItem} from "./SuperbillOrderItem";
import {ConfirmModal} from "../common/ConfirmModal";

export class SuperbillForm extends React.Component {
    static contextType = GlobalContext;

    static AUTOSAVE_DELAY = 2000;
    autosaveTimer = null;

    constructor(props) {
        super(props);

        this.state = {
            patient: null,
            superbill: null,
            suggestedIcd10s: [],
            suggestedOrders: [],
            selectedIcd10s: [],
            orders: [],
            orderMetadata: [],
            dirty: false,
            showLeaveModal: false,
            readOnly: true,
            internalProviders: [],
            saved: false
        }
    }

    setDirty = (callback) => {
        this.setState({dirty: true}, callback);
        if (this.autosaveTimer) {
            window.clearTimeout(this.autosaveTimer);
        }
        this.autosaveTimer = window.setTimeout(() => this.save(false), SuperbillForm.AUTOSAVE_DELAY);
    }

    componentDidMount() {
        this.setState({readOnly: !this.context.hasAnyRole(["System Admin", "Superbill Editor"])}, this.loadData);
    }

    getPatientId = () => {
        return this.getParam("patientId")
    }

    getParam = (name) => {
        let url= new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        if (params.has(name)) return params.get(name);
        return null;
    }

    loadData = () => {
        let patientId = this.getPatientId();
        if (patientId) {
            let apptDate = this.getParam("apptDate");

            this.context.apiRequest("GET", `/superbill?patientId=${patientId}${apptDate ? `&apptDate=${apptDate}` : ''}`)
                .then(d => {
                    if (d && d.data) {
                        let sb = d.data.superbill || this.defaultSuperbill(patientId, apptDate);
                        if (!sb.providerId && this.context.userProfile.providerId) {
                            sb.providerId = this.context.userProfile.providerId;
                        }

                        this.setState({
                            patient: d.data.patient,
                            superbill: sb,
                            selectedIcd10s: sb ? sb.diagnoses || [] : [],
                            orders: sb && sb.orders ? sb.orders : []
                        });
                    }
                });
            this.context.cachedGet("/superbill/orderMetadata", 300)
                .then(d => {
                    if (d && d.data) {
                        this.setState({orderMetadata: d.data});
                    }
                })

            this.context.cachedGet("/providers/internal?active=1", 300)
                .then(d => {
                    if (d && d.data) {
                        this.setState({internalProviders: d.data});
                    }
                })

            if (!this.state.readOnly) {
                this.getSuggestions();
            }
        }
    }

    defaultSuperbill = (patientId, apptDate) => {
        return {
            patientId,
            appointmentDate: apptDate,
            status: "NEW",
            prescriptionComments: "",
            generalComments: ""
        }
    }

    superbillFieldChanged = (e, overrideValue, callback) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setSuperbillField(fieldName, value, callback);
    };

    setSuperbillField = (fieldName, value, callback) => {
        this.setState(prvState => ({
            superbill: {
                ...prvState.superbill,
                [fieldName]: value,
            }
        }), () => this.setDirty(callback));
    };

    goBack = (force) => {

        if (!this.state.dirty || force) {
            window.location.href = "/superbill";
        } else {
            this.save(false)
                .then(() => {
                    window.location.href = "/superbill";
                });
        }
    }

    getSuggestions = () => {
        let patientId = this.getPatientId();
        if (patientId) {
            let newIcds = this.state.selectedIcd10s.map(i => i.icd10Code).join(',');
            this.context.apiRequest("GET", `/superbill/suggestions?patientId=${patientId}&newIcds=${newIcds}`)
                .then(d => {
                    if (d && d.data) {
                        let allOrders = [];
                        let allIcds = [];
                        let allClusters = [];
                        for (const sugg of d.data) {
                            if (sugg.orders && sugg.orders.length > 0) {
                                allOrders = allOrders.concat(sugg.orders);
                            }
                            if (sugg.icds && sugg.icds.length > 0) {
                                allIcds = allIcds.concat(sugg.icds)
                            }
                        }
                        this.setState({suggestedIcd10s: allIcds, suggestedOrders: allOrders});
                    }
                })
        }
    }

    icd10Selected = (code, desc) => {
        // only do this if the code is not already in the list
        if (!this.state.selectedIcd10s.some(i => i.icd10Code == code)) {
            let icds = [...this.state.suggestedIcd10s].filter(i => i.icd10Code !== code);
            let selected = [...this.state.selectedIcd10s];
            selected.push({icd10Code: code, description: desc});
            this.setState({suggestedIcd10s: icds, selectedIcd10s: selected}, () => this.setDirty(this.getSuggestions));
        }
    }

    removeSelectedIcd10 = (code, desc) => {
        let icds = [...this.state.suggestedIcd10s];
        let selected = [...this.state.selectedIcd10s].filter(i => i.icd10Code !== code);
        // only push it back onto the suggested list if it's not already there
        if (!this.state.suggestedIcd10s.some(i => i.icd10Code == code)) {
            icds.push({icd10Code: code, description: desc});
        }
        this.setState({suggestedIcd10s: icds, selectedIcd10s: selected}, () => this.setDirty(this.getSuggestions));
    }

    orderChanged = (orderMetaId, checked) => {
        let orders = [...this.state.orders];
        let index = orders.findIndex(o => o.orderMetaId == orderMetaId);
        if (checked) {
            // ensure is present
            if (index == -1) {
                orders.push({orderMetaId, options: []});
            }
        } else if (index >= 0) {
            // remove
            orders.splice(index, 1);
        }
        this.setState({orders}, this.setDirty);
    }

    optionChanged = (orderMetaId, optionId, value) => {
        let orders = [...this.state.orders];
        let order = orders.find(o => o.orderMetaId == orderMetaId);
        if (order) {
            // make a copy of the contents of the options array to trigger a state update
            let options = [...(order.options || [])];
            order.options = options;
            let option = options.find(opt => opt.optionId == optionId);
            if (!option) {
                option = {optionId, value};
                options.push(option);
            } else {
                option.value = value;
            }
            this.setState({orders}, this.setDirty);
        }
    }

    gatherDiagnoses = () => {
        return this.state.patient ? [...this.state.patient.diagnoses.map(d => ({...d, description: d.description || d.icdDescription})), ...(this.state.selectedIcd10s || [])] : [];
    }

    save = (submit) => {
        if (this.autosaveTimer) window.clearTimeout(this.autosaveTimer);
        let sb = this.state.superbill;
        if (submit) {
            sb.status = "SUBMITTED";
        }
        sb.diagnoses = this.state.selectedIcd10s;
        sb.orders = this.state.orders;

        return this.context.apiRequest("POST", "/superbill", sb)
            .then((d) => {
                if (d && d.data) {
                    let sb = this.state.superbill;
                    sb.id = d.data.id;
                    this.setState({superbill: sb}, () => {
                        this.setState({dirty: false, saved: true}, submit ? this.goBack : null)
                    });
                }
            });

    }

    getOrderValue = (orderMetaId) => {
        return this.state.orders.find(o => o.orderMetaId == orderMetaId);
    }

    isOrderSuggested = (orderMetaId) => {
        return this.state.suggestedOrders.some(o => o.orderMetaId == orderMetaId);
    }

    getSuggestedOptions = (orderMetaId) => {
        let order = this.state.suggestedOrders.find(o => o.orderMetaId == orderMetaId);
        return order ? order.options || [] : [];
    }

    generateDxSourceText = (d) => {
        if (d && d.source) {
            let src = d.source === "REFERRAL" ? "Ref" : "CNS";
            if (d.timestamp) {
                src += " " + moment(d.timestamp).format("M/D/YY");
            }
            return src;
        }
        return "";
    }

    printForm = () => {
        window.print();
    }

    render() {
        let p = this.state.patient;
        let sb = this.state.superbill;
        let allDiags = this.gatherDiagnoses();
        let internalOrders = this.state.orderMetadata.filter(o => o.internal == 1 && (!this.state.readOnly || this.getOrderValue(o.id) != null));
        let selectedInternalOrders = internalOrders.filter(o=> this.getOrderValue(o.id) != null);
        let externalOrders = this.state.orderMetadata.filter(o => o.internal == 0 && (!this.state.readOnly || this.getOrderValue(o.id) != null));
        let selectedExternalOrders = externalOrders.filter(o=> this.getOrderValue(o.id) != null);

        return <Container fluid style={{height: "100%"}}>
            { p && sb && <Form>
                <ConfirmModal okLabel={"Discard Changes"} cancelLabel={"Stay Here"} onOk={() => this.goBack(true)}
                              title={"Unsaved Changes"} prompt={"Leave this page? You have unsaved changes."}
                              show={this.state.showLeaveModal} onCancel={() => this.setState({showLeaveModal: false})}/>
                <Row className={"print-only mt-3"}>
                    <Col>
                        <h2>Superbill Order Form</h2>
                    </Col>
                </Row>
                <Row className={"mb-2 no-print"}>
                    <Col md={6}>
                    </Col>
                    <Col className={"text-right"} md={6}>
                        {!this.state.dirty && this.state.saved && <span className={"text-success d-inline-block"}>Saved <Glyph name={"check-lg"}/></span>}

                        <Button className={"ml-3"} type={"button"} variant={"secondary"} onClick={() => this.goBack(false)}>
                            <Glyph name={"arrow-left"} label={"Back to list"}/> Back
                        </Button>

                        <Button type={"button"} variant={"secondary"} className={"mr-3 ml-3"} onClick={this.printForm}>
                            <Glyph name={"printer"} label={"Print"}/> Print
                        </Button>

                        <Button type={"button"} variant={"secondary"}
                                onClick={() => this.save(true)}>
                            <Glyph name={"send-check"}/> Submit
                        </Button>
                    </Col>
                </Row>
                <fieldset disabled={this.state.readOnly}>
                    <Row>
                        <Col md={12}>
                            <Card className={"mb-1"}>
                                <Card.Body className={"p-2"}>
                                    <Row>
                                        <Col sm={3} lg={2}><FormLabel className={"font-weight-bold"}>Ordering Provider:</FormLabel></Col>
                                        <Col sm={3} lg={4}>
                                            <FormControl size={"sm"} className={"mb-2"} name={"providerId"} as={"select"} custom value={sb.providerId} onChange={this.superbillFieldChanged}>
                                                {
                                                    this.state.internalProviders.filter(p => p.titleCode).map(p => <option key={p.providerId} value={p.providerId}>
                                                        {p.firstName} {p.lastName}{`${p.titleCode ? ' '+p.titleCode : ''}`}
                                                    </option>)
                                                }
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3} lg={2}><FormLabel className={"font-weight-bold"}>Patient Name:</FormLabel></Col>
                                        <Col sm={3} lg={4}>{p.firstName} {p.lastName}</Col>
                                        <Col sm={3} lg={2}><FormLabel
                                            className={"font-weight-bold"}>DOB:</FormLabel></Col>
                                        <Col sm={3} lg={4}>{moment(p.dob).format("M/D/YYYY")}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3} lg={2}><FormLabel className={"font-weight-bold"}>Account Number:</FormLabel></Col>
                                        <Col sm={3} lg={4}>{p.externalPatientId}</Col>
                                        <Col sm={3} lg={2}><FormLabel className={"font-weight-bold"}>Appts Today:</FormLabel></Col>
                                        <Col sm={3} lg={4}>
                                            <Table size={"sm"} borderless>
                                                <tbody>
                                                {
                                                    p.appointments && p.appointments.length > 0 ? p.appointments.map(a =>
                                                        <tr key={a.appointmentId}>
                                                            <td>{moment(a.appointmentDateTime).format("h:mm A")}</td>
                                                            <td>{a.reasonCode}</td>
                                                            <td>{a.providerLast}</td>
                                                        </tr>) : <>(No appointments)</>
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3} lg={2}><FormLabel className={"font-weight-bold"}>Insurance plan(s):</FormLabel></Col>
                                        <Col sm={3} lg={4}>
                                            {
                                                p.insurances && p.insurances.length > 0 ? <ol className={"pl-3"}>
                                                    {
                                                        p.insurances.map(i => <li
                                                            key={'ins' + i.id}>{i.companyName} ({i.planCode})</li>)
                                                    }
                                                </ol> : <>(No plans on file)</>
                                            }
                                        </Col>
                                        <Col sm={3} lg={2} className={"font-weight-bold"}>Ref Provider:</Col>
                                        <Col sm={3} lg={4}>
                                            {
                                                p.referral && p.referral.provider1 ? <>{p.referral.provider1First} {p.referral.provider1.provider1Last}</> : <>(None)</>
                                            }
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body className={"p-2 mb-1"}>
                                    <Row>
                                        {!this.state.readOnly &&
                                            <Col md={6} className={"no-print"}>
                                                <FormLabel className={"font-weight-bold"}>Search Diagnoses</FormLabel>
                                            </Col>
                                        }
                                        <Col md={6}>
                                            <FormLabel className={"font-weight-bold"}>Patient's Diagnoses</FormLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {!this.state.readOnly &&
                                            <Col md={6} className={"no-print"}>
                                                <Row>
                                                    <Col sm={12}><ICD10Search id={"icd10-search"}
                                                                              icd10Selected={this.icd10Selected}/></Col>
                                                </Row>
                                                <Row className={"mt-2"}>
                                                    <Col><FormLabel className={"font-weight-bold"}>Most Common Diagnoses for Similar Patients</FormLabel></Col>
                                                </Row>
                                                <ListGroup variant={"flush"} style={{height: "200px", overflowY: "auto"}}>
                                                    {
                                                        this.state.suggestedIcd10s && this.state.suggestedIcd10s.map(i =>
                                                            <ListGroupItem className={"p-1"} key={i.icd10Code}>
                                                                <Row>
                                                                    <Col sm={3} className={"text-small"}>{i.icd10Code}</Col>
                                                                    <Col sm={7}>{i.description}</Col>
                                                                    <Col sm={2} className={"text-right"}><Glyph
                                                                        name={"arrow-right"} className={"clickable mr-3"}
                                                                        onClick={() => this.icd10Selected(i.icd10Code, i.description)}/></Col>
                                                                </Row>
                                                            </ListGroupItem>)
                                                    }
                                                </ListGroup>
                                            </Col>
                                        }
                                        <Col md={6}>
                                            <ListGroup variant={"flush"} style={{height: "275px", overflowY: "auto"}}>
                                                {
                                                    p.diagnoses && p.diagnoses.map(i => <ListGroupItem className={"p-1"}
                                                                                                       key={i.icd10Code}>
                                                        <Row>
                                                            <Col sm={4} className={"text-small"}>
                                                                {i.icd10Code || '(Ref Text)'}
                                                                {i.source && <>
                                                                    <br/>
                                                                    <small
                                                                        className={"text-secondary"}><i>{this.generateDxSourceText(i)}</i></small>
                                                                </>}
                                                            </Col>
                                                            <Col sm={6}>{i.description || i.icdDescription}</Col>
                                                            <Col sm={2} className={"text-right"}></Col>
                                                        </Row>
                                                    </ListGroupItem>)
                                                }
                                                {
                                                    this.state.selectedIcd10s && this.state.selectedIcd10s.map(i =>
                                                        <ListGroupItem className={"p-1"} key={i.icd10Code}>
                                                            <Row>
                                                                <Col sm={4} className={"text-small"}>{i.icd10Code}</Col>
                                                                <Col sm={6}>{i.description}</Col>
                                                                <Col sm={2} className={"text-right"}>
                                                                    {!this.state.readOnly &&
                                                                        <Glyph name={"x-lg"}
                                                                                className={"clickable mr-3 no-print"}
                                                                                onClick={() => this.removeSelectedIcd10(i.icd10Code, i.description)}/>}
                                                                </Col>
                                                            </Row>
                                                        </ListGroupItem>)
                                                }
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card className={"mt-1"}>
                                <Card.Body className={"p-2"}>
                                    <Row>
                                        <FormGroup controlId={"prescriptions"} as={Col} sm={6}>
                                            <FormLabel className={"font-weight-bold"}>Prescriptions:</FormLabel>
                                            <FormControl as={"textarea"} rows={3} name={"prescriptionComments"}
                                                         value={sb.prescriptionComments}
                                                         onChange={this.superbillFieldChanged}/>
                                        </FormGroup>
                                        <FormGroup controlId={"comments"} as={Col} sm={6}>
                                            <FormLabel className={"font-weight-bold"}>Additional Comments:</FormLabel>
                                            <FormControl as={"textarea"} rows={3} name={"generalComments"}
                                                         value={sb.generalComments}
                                                         onChange={this.superbillFieldChanged}/>
                                        </FormGroup>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Card className={"mt-1"}>
                                <Card.Body className={"p-2"}>
                                    <FormLabel className={"font-weight-bold"}>CNS Orders</FormLabel>
                                    <div className={"no-print"}>
                                        {
                                            internalOrders.length > 0 ?
                                                internalOrders.map(o =>
                                                    <SuperbillOrderItem key={o.name} metadata={o}
                                                                        value={this.getOrderValue(o.id)}
                                                                        itemChanged={(checked) => this.orderChanged(o.id, checked)}
                                                                        optionChanged={(optionId, value) => this.optionChanged(o.id, optionId, value)}
                                                                        diagnoses={allDiags}
                                                                        suggested={this.isOrderSuggested(o.id)}
                                                                        suggestedOptions={this.getSuggestedOptions(o.id)}
                                                                        displayOnly={this.state.readOnly}/>)
                                                : <Row><Col>None</Col></Row>
                                        }
                                    </div>
                                    <div className={"print-only"}>
                                        {
                                            // For print, only show orders that were selected
                                            selectedInternalOrders.length > 0 ?
                                                selectedInternalOrders.map(o =>
                                                    <SuperbillOrderItem key={o.name+"_"} metadata={o}
                                                                        displayOnly={true}
                                                                        value={this.getOrderValue(o.id)}
                                                                        diagnoses={allDiags}/>)
                                                : <Row><Col>None</Col></Row>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className={"mt-1"}>
                                <Card.Body className={"p-2"}>
                                    <FormLabel className={"font-weight-bold"}>Outside Orders</FormLabel>
                                    <div className={"no-print"}>
                                        {
                                            externalOrders.length > 0 ?
                                                externalOrders.map(o =>
                                                    <SuperbillOrderItem key={o.name} metadata={o}
                                                                        value={this.getOrderValue(o.id)}
                                                                        itemChanged={(checked) => this.orderChanged(o.id, checked)}
                                                                        optionChanged={(optionId, value) => this.optionChanged(o.id, optionId, value)}
                                                                        diagnoses={allDiags}
                                                                        suggested={this.isOrderSuggested(o.id)}
                                                                        suggestedOptions={this.getSuggestedOptions(o.id)}
                                                                        displayOnly={this.state.readOnly}/>)
                                                : <Row><Col>None</Col></Row>
                                        }
                                    </div>
                                    <div className={"print-only"}>
                                        {
                                            // For print, only show orders that were selected
                                            selectedExternalOrders.length > 0 ?
                                                selectedExternalOrders.map(o =>
                                                    <SuperbillOrderItem key={o.name} metadata={o}
                                                                        displayOnly={true}
                                                                        value={this.getOrderValue(o.id)}
                                                                        diagnoses={allDiags}/>)
                                                : <Row><Col>None</Col></Row>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        !this.state.readOnly ?
                        <Row className={"mt-3 mb-4 no-print"}>
                            <Col sm={6} className={"type-italic"}>
                                <Glyph name={"stars"} className={"ml-2 text-success"}/> = <b>procedure</b> is
                                suggested for similar patients.</Col>
                            <Col sm={6} className={"text-right"}>
                                {!this.state.dirty && this.state.saved && <span className={"text-success d-inline-block"}>Saved <Glyph name={"check-lg"}/></span>}

                                <Button className={"ml-3"} type={"button"} variant={"secondary"} onClick={() => this.goBack(false)}>
                                    <Glyph name={"arrow-left"} label={"Back to list"}/> Back
                                </Button>

                                <Button type={"button"} variant={"secondary"} className={"mr-3 ml-3"} onClick={this.printForm}>
                                    <Glyph name={"printer"} label={"Print"}/> Print
                                </Button>

                                <Button type={"button"} variant={"secondary"}
                                        onClick={() => this.save(true)}>
                                    <Glyph name={"send-check"}/> Submit
                                </Button>
                            </Col>
                        </Row> : <></>
                    }
                    {/*Spacer for scrolling*/}
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                </fieldset>
            </Form>}
        </Container>
    }
}