import React from 'react';
import {GlobalContext} from "../GlobalContext";
import logo from "../cns.png";
import {
    Navbar,
    Nav,
    Image,
    NavItem,
    Dropdown,
    DropdownButton,
    Toast,
    OverlayTrigger, Col, Row, Table
} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {RoleWrapper} from "./common/RoleWrapper";
import {UserProfileModal} from "./user/UserProfileModal";
import {ChangePasswordModal} from "./user/ChangePasswordModal";
import {Glyph} from "./common/Glyph";
import {NotificationsPopover} from "./common/NotificationsPopover";

export class AppHeader extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            profileModalVisible: false,
            passwordModalVisible: false
        };
    }

    showProfileModal = () => {
        this.setState({profileModalVisible: true});
    };

    hideProfileModal = () => {
        this.setState({profileModalVisible: false});
    };

    showPasswordModal = () => {
        this.setState({passwordModalVisible: true});
    };

    hidePasswordModal = () => {
        this.setState({passwordModalVisible: false});
    };

    userProfileOk = (u) => {
        this.context.apiRequest('POST', '/users', u)
            .then(this.context.refreshMyProfile)
            .then(this.hideProfileModal);
    }

    render() {
        let u = this.context.userProfile || {"firstName":"", "lastName":""};
        let toastInfo = this.context.toastInfo || {};
        let hasNotifs = (this.props.notifications && this.props.notifications.length > 0);
        let hasUnreadNotifs = this.props.notifications && this.props.notifications.some(n => (n.isRead == 0));

        return (
            <Navbar id={"header"} bg="dark" expand="lg" className={"mb-3 no-print"}>
                <div style={{
                    position: 'absolute',
                    top: '65px',
                    right: '0px',
                    zIndex: 9999
                }}>
                    {/* */}
                    <Toast className={"bg-light"} show={this.context.showToast} autohide delay={5000} onClose={this.context.hideToast} >
                        <Toast.Header>
                            <strong className="mr-auto">{toastInfo.title}</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <Table borderless size={"sm"} className={"w-100"}>
                                <tbody>
                                <tr>
                                    {
                                        toastInfo.iconName &&
                                        <td className={"text-center align-middle"}><Glyph name={toastInfo.iconName}
                                                                                          style={toastInfo.iconColor ? {
                                                                                              fontSize: "1.5rem",
                                                                                              color: toastInfo.iconColor
                                                                                          } : {fontSize: "2.0rem"}}/>
                                        </td>
                                    }
                                    <td>{toastInfo.message}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Toast.Body>
                    </Toast>
                </div>

                <Navbar.Brand><Image src={logo}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className={"navbar-dark"}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <RoleWrapper roles={["Superbill Editor", "Superbill Viewer"]}><LinkContainer to={"/superbill"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Superbills</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["Scheduling", "Supervisor", "Scheduling (Read Only)", "Authorizations", "Medical Assistant", "System Admin"]}><LinkContainer to={"/patients"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Patients</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["Scheduling", "Supervisor", "Marketing", "System Admin"]}><LinkContainer to={"/marketing"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Marketing</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["Liens", "Supervisor","System Admin"]}><LinkContainer to={"/liens"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Liens</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Authorizations"]}><LinkContainer to={"/authBoard"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Auth Board</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Supervisor","Authorizations","Scheduling","Marketing","Medical Assistant"]}><LinkContainer to={"/reports"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Reports</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Supervisor"]}><LinkContainer to={"/admin"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>System Admin</Nav.Item></LinkContainer></RoleWrapper>
                    </Nav>

                    <OverlayTrigger
                        trigger={"click"}
                        placement={"bottom"}
                        rootClose
                        overlay={<NotificationsPopover notifications={this.props.notifications}/>}

                    >
                        <Glyph name={hasNotifs ? "bell-fill" : "bell"} className={`clickable mr-3 ${hasUnreadNotifs ? "text-danger" : "text-light"}`} style={{fontSize: '1.2rem'}}/>
                    </OverlayTrigger>

                    <DropdownButton as={NavItem} className={"p-0"} menuAlign="right" id="nav-user-dropdown" title={<>{u.iconName && <Glyph name={u.iconName} className={"mr-2"} style={{fontSize: "1.2rem", color: "white"}}/>}{u.firstName} {u.lastName.substr(0,1)}.</>} variant={"secondary"}>
                        <Dropdown.Item eventKey="user-profile" onClick={this.showProfileModal}>My Profile</Dropdown.Item>
                        <Dropdown.Item eventKey="change-password" onClick={this.showPasswordModal}>Change Password</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="logout" onClick={this.context.userDidLogout}>Logout</Dropdown.Item>
                    </DropdownButton>
                </Navbar.Collapse>
                <UserProfileModal onCancel={this.hideProfileModal} show={this.state.profileModalVisible} item={u} onOk={this.userProfileOk}/>
                <ChangePasswordModal onCancel={this.hidePasswordModal} onOk={this.hidePasswordModal} show={this.state.passwordModalVisible}/>
            </Navbar>
        );
    }
}