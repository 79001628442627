import React from "react";
import {Col, ListGroupItem, Row} from "react-bootstrap";
import moment from "moment-timezone";
import {Glyph} from "../common/Glyph";

export class PatientListRow extends React.Component {

    getSbStatusGlyph = (status) => {
        if (!status) {
            return <Glyph name={"ban"} label={"No superbill yet"} style={{fontSize: "2.5rem", color: "#ccc"}}/>
        }
        switch (status) {
            case "NEW":
                return <Glyph name={"pencil-square"} label={"In Progress"} style={{fontSize: "2.5rem", color: "#0483ea"}}/>
            case "SUBMITTED":
                return <Glyph name={"send-check"} label={"Submitted"} style={{fontSize: "2.5rem", color: "#9d04ea"}}/>
            case "COMPLETE":
                return <Glyph name={"check-circle"} label={"Complete"} style={{fontSize: "2.5rem", color: "#278649"}}/>
        }
        return <></>
    }

    render() {
        let p = this.props.patient;
        let apptDate = p.appointmentDateTime ? moment(p.appointmentDateTime).format("YYYY-MM-DD") : "";
        return <ListGroupItem action href={`/superbill/form?patientId=${p.patientId}&apptDate=${apptDate}`}>
            <Row>
                <Col md={9} lg={10}>
                    <Row>
                        <Col className={"font-weight-bold"}>{p.firstName} {p.lastName} {p.dob ? moment(p.dob).format("M/D/YY") : ""}</Col>
                        <Col className={"text-right"}>
                            Acct# {p.externalPatientId} {p.externalPatientId2 && ` Pt# ${p.externalPatientId2}`}
                        </Col>
                    </Row>
                    <Row>
                        <Col>Appt: {p.appointmentDateTime ? moment(p.appointmentDateTime).format("M/D/YY h:mm A") : "N/A"}</Col>
                        <Col className={"text-right"}>
                            {p.reasonCodes}
                        </Col>
                    </Row>
                </Col>
                <Col className={"text-right"}>
                    {this.getSbStatusGlyph(p.superbillStatus)}
                </Col>
            </Row>
        </ListGroupItem>
    }
}