import React from "react";
import {DropdownButton, FormCheck, FormControl, InputGroup} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";

export class SuperbillOtherInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: ""
        }
    }

    valueChanged = (e) => {
        let val = e.target.value;
        this.setState({inputValue: val}, () => this.props.onValueChanged(val));
    }

    toggled = (e) => {
        this.props.onValueChanged(e.target.checked ? this.state.inputValue : null);
    }

    render() {
        return <InputGroup>
            <FormCheck label={this.props.label || "Other"} checked={this.props.value != null} onChange={this.toggled} inline/>
            <FormControl value={this.props.value || ""} onChange={this.valueChanged}/>
        </InputGroup>
    }
}