import React from "react";
import {Col, Row, Form, FormControl, Table, Card, FormLabel} from "react-bootstrap";
import {SuperbillOrderItem} from "../../superbill/SuperbillOrderItem";
import {GlobalContext} from "../../../GlobalContext";

export class ClusterDetail extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            showSamples: false,
            showFullICDList: false,
            icdMap: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.showFullICDList && !prevState.showFullICDList) {
            this.loadIcdMap();
        }
    }

    loadIcdMap = () => {
        let config = this.props.modelContent.config;
        if (config && config.dataColumns) {
            let i=0;
            for (const dc of config.dataColumns) {
                if (dc.comparator == "TEXT_LTR") {
                    let mc = this.props.modelContent.clusters.find(c1 => c1.id == this.props.cluster.uuid);
                    if (mc) {
                        let center = mc.centroid[i];
                        let codeCsv = center.map(c => c.value).join(",");
                        // Load the ICD10 descriptions
                        this.context.cachedGet(`/icd10/lookup?codes=${codeCsv}`, 60)
                            .then(d => {
                                if (d && d.data) {
                                    let map = this.state.icdMap;
                                    for (const icd of d.data) {
                                        map[icd.code] = icd.description;
                                    }
                                    this.setState({icdMap: map});
                                }
                            })
                    }
                }
                i++;
            }
        }
    }

    buildClusterInfo = (colMeta, colIndex) => {
        let mc = this.props.modelContent.clusters.find(c1 => c1.id == this.props.cluster.uuid);
        let {config, colStats} = this.props.modelContent;

        if (mc) {
            let center = mc.centroid[colIndex];

            switch (colMeta.comparator) {
                case "TEXT_EXACT_MATCH_CI":
                    // Center is the most frequent value
                    return <Row><Col md={"auto"}>Most common value:</Col><Col><b>{center.value}</b> ({Math.round(center.frequency*100)}%)</Col></Row>
                case "NUMBER":
                    // Center is the average value (in the form of # of std dev's from the mean)
                    let stdDev = colStats[colIndex].stddev;
                    let mean = colStats[colIndex].mean;
                    let centerVal = center*stdDev + mean;
                    return <Row><Col md={"auto"}>Average:</Col><Col><b>{Math.round(centerVal*100)/100}</b></Col></Row>
                case "TEXT_DOC_SIMILARITY":
                    let words = [];
                    for (const word in center) {
                        if (center.hasOwnProperty(word)) {
                            words.push({word, count: center[word]});
                        }
                    }
                    let pop = mc.population;
                    words.sort((a, b) => (a.count < b.count ? 1 : (a.count > b.count ? -1 : 0)));
                    return <Row><Col md={"auto"}>Most common words:</Col><Col>{
                        words.slice(0, 10).map((w,i) => <div key={w+i}><b>{w.word}</b> ({Math.round((w.count/pop)*100)}%)</div>)
                     }</Col></Row>
                case "TEXT_LTR":
                    return <Row>
                        <Col>
                            <a href={"#"} onClick={() => this.setState({showFullICDList: !this.state.showFullICDList})}>
                                {this.state.showFullICDList ? "Hide" : "Show"}
                            </a>
                                {this.state.showFullICDList && <Table striped borderless size={"sm"}>
                                    <tbody>
                                    {center.map(e => <tr key={e.value}>
                                        <td>{e.value}</td>
                                        <td>{this.state.icdMap[e.value] || ""}</td>
                                    </tr>)}
                                    </tbody>
                                </Table>}
                        </Col>
                    </Row>
            }
        }
        return <></>
    }

    orderChanged = (orderMetaId, checked) => {
        if (this.props.cluster) {
            let orders = [...this.props.cluster.suggestedOrders || []];
            let index = orders.findIndex(o => o.orderMetaId == orderMetaId);
            if (checked) {
                // ensure is present
                if (index == -1) {
                    orders.push({orderMetaId, options: []});
                }
            } else if (index >= 0) {
                // remove
                orders.splice(index, 1);
            }
            let event = {
                target: {
                    name: "suggestedOrders",
                    value: orders
                }
            }
            this.props.onClusterChange(event);
        }
    }

    optionChanged = (orderMetaId, optionId, value) => {
        if (this.props.cluster) {
            let orders = [...this.props.cluster.suggestedOrders || []];
            let order = orders.find(o => o.orderMetaId == orderMetaId);
            if (order) {
                if (!order.options) order.options = [];
                let option = order.options.find(opt => opt.optionId == optionId);
                if (!option) {
                    option = {optionId, value};
                    order.options.push(option);
                } else {
                    option.value = value;
                }
                let event = {
                    target: {
                        name: "suggestedOrders",
                        value: orders
                    }
                }
                this.props.onClusterChange(event);
            }
        }
    }

    getOrderValue = (orderMetaId) => {
        let orders = this.props.cluster.suggestedOrders || [];
        return orders.find(o => o.orderMetaId == orderMetaId);
    }

    render() {
        let c = this.props.cluster;
        let config = this.props.modelContent.config;
        let mc = this.props.modelContent.clusters.find(c1 => c1.id == this.props.cluster.uuid);
        let internalOrders = this.props.orderMetadata.filter(o => o.internal == 1 && (!this.state.readOnly || this.getOrderValue(o.id) != null));
        let externalOrders = this.props.orderMetadata.filter(o => o.internal == 0 && (!this.state.readOnly || this.getOrderValue(o.id) != null));

        return c && config && mc ? <>
            <Form.Row>
                <Col md={6}>
                    <Form.Row>
                        <Col md={4}>
                            <Form.Group controlId="name">
                                <Form.Label size="sm">Cluster Name:</Form.Label>
                                <FormControl size={"sm"} required name={"name"} value={c.name} onChange={this.props.onClusterChange}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group controlId="description">
                                <Form.Label size="sm">Description:</Form.Label>
                                <FormControl as={"textarea"} rows={2} size={"sm"} name={"description"} value={c.description || ""} onChange={this.props.onClusterChange}/>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={6}>
                            <Card className={"mt-1"}>
                                <Card.Body className={"p-2"}>
                                    <FormLabel className={"font-weight-bold"}>CNS Orders</FormLabel>
                                    {
                                        internalOrders.length > 0 ?
                                            internalOrders.map(o =>
                                                <SuperbillOrderItem key={o.name} metadata={o}
                                                                    value={this.getOrderValue(o.id)}
                                                                    itemChanged={(checked) => this.orderChanged(o.id, checked)}
                                                                    optionChanged={(optionId, value) => this.optionChanged(o.id, optionId, value)}
                                                                    suggested={false}
                                                                    readOnly={false}/>)
                                            : <Row><Col>None</Col></Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className={"mt-1"}>
                                <Card.Body className={"p-2"}>
                                    <FormLabel className={"font-weight-bold"}>Outside Orders</FormLabel>
                                    {
                                        externalOrders.length > 0 ?
                                            externalOrders.map(o =>
                                                <SuperbillOrderItem key={o.name} metadata={o}
                                                                    value={this.getOrderValue(o.id)}
                                                                    itemChanged={(checked) => this.orderChanged(o.id, checked)}
                                                                    optionChanged={(optionId, value) => this.optionChanged(o.id, optionId, value)}
                                                                    suggested={false}
                                                                    readOnly={false}/>)
                                            : <Row><Col>None</Col></Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Form.Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <Table borderless size={"sm"}>
                                <tbody>
                                {
                                    config && config.dataColumns.map((col, i) => <tr key={col.name}>
                                        <td><b>{col.name}</b></td>
                                        <td>{this.buildClusterInfo(col, i)}</td>
                                    </tr>)
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div>
                                <a href={"#"} onClick={() => this.setState({showSamples: !this.state.showSamples})}>{this.state.showSamples ? "Hide" : "Show"} Samples</a>
                            </div>
                            { this.state.showSamples &&
                                <Table striped borderless size={"sm"}>
                                    <thead>
                                    <tr>
                                        {
                                            config && config.dataColumns.map(col => <td key={col.name+"b"}>{col.name}</td>)
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mc.examples && mc.examples.map(e => <tr key={e.key}>
                                            {
                                                e.data.map(d => <td>
                                                    {
                                                        Array.isArray(d) ? d.map((p,i) => <div key={p+i}>{p}</div>) : d
                                                    }
                                                </td>)
                                            }</tr>)
                                    }
                                    </tbody>
                                </Table>
                            }
                        </Col>
                    </Row>
                </Col>
            </Form.Row>
        </> : <></>
    }
}