import React from "react";
import {Button, DropdownButton, FormCheck, FormControl, InputGroup, OverlayTrigger, Popover} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";

export class SuperbillDxInput extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            console.log(`value changed from ${prevProps.value} to ${this.props.value}`);
        }
    }

    dxChanged = (e, icd10Code) => {
        let selected = this.props.value ? this.props.value.split(', ') : [];
        if (e.target.checked) {
            selected.push(icd10Code);
        } else {
            selected.splice(selected.findIndex(i => i == icd10Code), 1);
        }
        let csv = selected.join(', ');
        this.props.onDxChanged(csv);
    }

    render() {
        let selected = this.props.value ? this.props.value.split(', ') : [];

        return <InputGroup>
            <FormControl
                placeholder="Supporting DXs"
                aria-label="Supporting DXs"
                aria-describedby="basic-addon2"
                disabled
                value={this.props.value || ""}/>
            <OverlayTrigger
                trigger="click"
                rootClose
                placement={"top"}
                overlay={
                    <Popover id={`popover-dx`}>
                        <Popover.Content>
                            {
                                (this.props.allIcds || []).map(icd => <DropdownItem key={"icd"+icd.icd10Code} >
                                    <FormCheck label={`${icd.icd10Code} ${icd.description}`}
                                               checked={selected.includes(icd.icd10Code)}
                                               onChange={(e) => this.dxChanged(e, icd.icd10Code)}/>
                                </DropdownItem>)
                            }
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button as={InputGroup.Append} size={"sm"} variant={"secondary"}>Select DX</Button>
            </OverlayTrigger>
        </InputGroup>
    }
}