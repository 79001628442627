import {Col, FormCheck, FormControl, Row} from "react-bootstrap";
import React from "react";
import {Glyph} from "../common/Glyph";
import {SuperbillDxInput} from "./SuperbillDxInput";
import {SuperbillOtherInput} from "./SuperbillOtherInput";

export class SuperbillOrderItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        }
    }

    itemChanged = (e) => {
        /**
         * itemChanged={(checked) => this.orderChanged(o.id, checked)}
         *  optionChanged={(optionId, value) => this.optionChanged(o.id, optionId, value)
         */
        let checked = e.target.checked;
        this.props.itemChanged(checked);
        if (checked && this.state.collapsed) {
            this.setState({collapsed: false});
        } else if (!checked && !this.state.collapsed) {
            this.setState({collapsed: true});
        }
    }

    getOptionValue = (optionId) => {
        let val = this.props.value;
        if (val && val.options) {
            let option = val.options.find(o => o.optionId == optionId);
            if (option) {
                return option.value;
            }
        }
        return null;
    }

    getOptionDisplayValue = (o) => {
        let val = this.getOptionValue(o.optionId);
        switch(o.type) {
            case "BOOL":
                return val ? o.optionName : "";
            case "DX":
                return "DX: " + val;
            default:
                return val || "";
        }
    }

    isOptionSuggested = (optionId) => {
        let sugg = this.props.suggestedOptions;
        if (sugg && sugg.some(o => o.optionId == optionId)) {
            return true;
        }
        return false;
    }

    render() {
        let meta = this.props.metadata;
        let options = meta.options || [];
        if (!this.props.diagnoses || this.props.diagnoses.length == 0) {
            // Filter out any DX options - no diagnoses present
            options = options.filter(o => o.type != "DX");
        }
        let val = this.props.value;
        let selectedOptions = options.filter(o => this.getOptionValue(o.optionId) != null).sort((a,b) => a.type == "DX" ? 1 : 0);
        let readOnly = this.props.displayOnly;

        return <><Row>
            <Col sm={"auto"}>
                {
                    readOnly ? <>{meta.name}</>
                        : <FormCheck type={"checkbox"} label={meta.name} className={`d-inline-block ${!readOnly && this.props.suggested ? "font-weight-bold" : ""}`} onChange={this.itemChanged} checked={(val != null)}/>
                }

                {
                    this.state.collapsed && selectedOptions.length > 0 && <>  [
                        { selectedOptions.map(o => this.getOptionDisplayValue(o)).join(', ') }
                    ]</>
                }
                {
                    !readOnly && this.props.suggested && <Glyph name={"stars"} className={"ml-2 text-success"}/>
                }
            </Col>
            {
                // Only show hinge if item is selected and not in read only mode
                val != null && !readOnly && options && options.length > 0 &&
                <Col className={"text-right"}>
                    <Glyph name={this.state.collapsed ? "plus-circle" : "dash-circle"} className={"clickable"} onClick={() => this.setState({collapsed: !this.state.collapsed})}/>
                </Col>
            }
        </Row>
        {
            options && options.length > 0 && !this.state.collapsed && options.map(o => <Row key={meta.name + o.optionName} className={this.state.collapsed ? "invisible" : ""}>
                <Col sm={{offset: 1}}>
                    {
                        o.type == "DX" && this.props.diagnoses && this.props.diagnoses.length > 0 &&
                        <SuperbillDxInput allIcds={this.props.diagnoses} value={this.getOptionValue(o.optionId)} onDxChanged={(csv) => this.props.optionChanged(o.optionId, csv)}/>
                    }
                    {
                        o.type == "BOOL" &&
                            <FormCheck type={"checkbox"} label={o.optionName} checked={new String(this.getOptionValue(o.optionId)) == "true"}
                                       className={`d-inline-block ${this.isOptionSuggested(o.optionId) ? "font-weight-bold" : ""}`}
                                       onChange={(e) => this.props.optionChanged(o.optionId, e.target.checked)}/>
                    }
                    {
                        !readOnly && this.isOptionSuggested(o.optionId) && <Glyph name={"stars"} className={"ml-2 text-success"}/>
                    }
                    {
                        o.type == "OTHER" &&
                        <SuperbillOtherInput value={this.getOptionValue(o.optionId)} onValueChanged={(val) => this.props.optionChanged(o.optionId, val)}/>
                    }
                </Col>
            </Row>)
        }
        </>
    }
}