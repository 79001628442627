import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

export class AppFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Container id="appFooter" className={"border-top bg-white no-print"} fluid>
                <Row>
                    <Col className={"text-muted text-center"}><small>&copy; {new Date().getFullYear()} V1S10N Consulting Services & Clinical Neurology Specialists</small></Col>
                </Row>
            </Container>
        );
    }
}