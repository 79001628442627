import {ItemList} from "../../common/ItemList";
import {Glyph} from "../../common/Glyph";
import React from "react";
import {AdminClusterModelModal} from "./AdminClusterModelModal";
import {GlobalContext} from "../../../GlobalContext";

export class AdminClusterModelList extends ItemList {
    static contextType = GlobalContext;

    idField = 'id';
    // updateProperty = 'patientId';
    itemTypeName = 'Cluster Model';
    newItem = () => {
        return {
            name: '',
            description: '',
            content: '',
            active: 0
        };
    };
    headerColumns = ['Name', 'Category', 'Description', 'Active'];
    headerColumnClasses = ['', 'text-center', '', 'text-center'];
    dataColumnClasses = ['', 'text-center', '', 'text-center'];
    addSupported = true;
    editSupported = true;
    deleteSupported = false;
    paging = false;
    modalClass = AdminClusterModelModal;

    getBaseUrl = () => {
        return `/bi/kmeans/model`;
    };

    /**
     * @param r
     * @returns the contents of the table cells for a particular item.
     */
    getItemCellContents = (model) => {
        return [model.name, model.category, model.description, <Glyph name={model.active ? 'check-circle' : 'ban'}/>];
    };

}